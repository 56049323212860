import { Link, useParams } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { useEffect, useState } from "react"
import moment from "moment"
import constant from "../../constant"
import { Rating } from 'react-simple-star-rating'

const OrderDetails = () => {

    const params = useParams()
    const [bookingDetail, setBookingDetail] = useState()
    const [isPaymentDone, setIsPaymentDone] = useState()
    const [isPayment, setIsPayment] = useState()
    const [mealCost, setMealCost] = useState()
    const [tollTax, setTollTax] = useState()
    const [otherCost, setOtherCost] = useState()
    const [paymentMode, setPaymentMode] = useState()
    const [paymentRef, setPaymentRef] = useState()
    const [image, setImage] = useState()
    useEffect(() => {
        getBookingsHandler();
    }, [])
    const calculationHandler = () => {
        let remainingcost = (((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.carData.perKmCharge+1 : bookingDetail.carData.perKmCharge)) - (isPayment / 100))
        let total = remainingcost
        if (mealCost) {
            total = total + (+mealCost);
        }
        if (tollTax) {
            total = total + (+tollTax);
        }
        if (otherCost) {
            total = total + (+otherCost);
        }
        console.log(typeof (mealCost), remainingcost + mealCost)
        return total.toFixed(0)
    }
    const getBookingsHandler = async () => {
        try {
            const response = await services.order.BOOKING_DETAIL(params.id);
            let data = response.data;
            
            data.BookingDetails.sort(function(a, b) {
                    return parseFloat(a.id) - parseFloat(b.id);
                });
           
            setBookingDetail(data)

            if (data?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails[0]?.paymentStatus == 'success') {
                setIsPayment(data?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails[0]?.amount);
                setIsPaymentDone(data?.BookingDetails[0]?.BookingPayment?.BookingPaymentDetails[0]?.paymentStatus)
            }
            if (data?.BookingDetails[0]?.bookingStatus == 'ended') {
                let index = data?.BookingDetails[0]?.BookingPayment.BookingPaymentDetails.length;
                let obj = data?.BookingDetails[0]?.BookingPayment.BookingPaymentDetails[index - 1].transactionData;
                setMealCost(obj.mealCost)
                setTollTax(obj.tollTax)
                setOtherCost(obj.otherCost)
                setPaymentRef(obj.paymentRef)
                setPaymentMode(obj.paymentMode)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (<>
        <Header />
        <SideBar />
        <main class="main">




            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        {bookingDetail && <div class="user-profile-card">
                                            <div class="user-profile-card-header">
                                                <h4 class="user-profile-card-title">Booking Detail #{bookingDetail.id}</h4>

                                            </div>
                                            <div class="row">

                                                <div class="col-md-12 mx-auto">
                                                    <div class="booking-form shadow-none">

                                                        <div class="col-md-12 col-lg-12 filter-item cat1 cat2">
                                                            <div class="row align-items-center ">
                                                                <a className="col-md-6 col-12"><div class="taxi-img">
                                                                    <img src={constant.IMAGE_URL + bookingDetail?.Car.photos[0]} crossorigin="anonymous" width={'350px'} />
                                                                </div></a>
                                                                <div class="taxi-content col-md-6 col-12">
                                                                    <div class="taxi-head">
                                                                        <span> <b>Selected Car</b></span><h4>{bookingDetail.Car.brand} {bookingDetail.Car.name}</h4>

                                                                    </div>
                                                                    <div class="taxi-feature">
                                                                        <ul className="">
                                                                            <li> Estimated KM: <span> <b>{(bookingDetail.distanceBreakdown.distance / 1000).toFixed(0)} KM</b></span></li>
                                                                            <li> Trip Type: <span> <b className="text-capitalize">{bookingDetail.tripType}</b></span></li>
                                                                            <li> Assigned Car Numbers: <span> <b>{bookingDetail.carData?.vendorCar?.rcNumber}</b></span></li>
                                                                            <li> Total Amount to be paid: <span> <b>Rs. {((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.carData.perKmCharge+1 : bookingDetail.carData.perKmCharge)).toFixed(0)}</b></span></li>
                                                                            <li> Amount paid online: <span> <b className="bg-success text-white">Rs. {isPayment && isPayment/100}</b></span></li>


                                                                        </ul>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {bookingDetail?.driverData && <><div class="user-profile-card-header mt-2">
                                                            <h4 class="user-profile-card-title">Driver Details</h4>

                                                        </div>
                                                            <div class="team-single-wrapper">
                                                                <div class="row align-items-center">
                                                                    <div class="col-lg-3">
                                                                        <div class="team-single-img">
                                                                            <img src={constant.IMAGE_URL + bookingDetail?.driverData.DriverDetail.photo} crossorigin="anonymous" className="img-fluid" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-7">
                                                                        <div class="team-single-content">
                                                                            <div class="team-single-name">
                                                                                <h3>{bookingDetail?.driverData?.firstName} {bookingDetail?.driverData?.lastName}</h3>

                                                                            </div>

                                                                            <div class="team-single-info">
                                                                                <ul>
                                                                                    <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-phone"></i>
                                                                                            Phone:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.driverData.phoneNumber}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-phone"></i>
                                                                                            Alternate Contact:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.vendorData.phoneNumber}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-envelope"></i>
                                                                                            Email:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.driverData.email}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="team-single-info-left"><i class="far fa-tools"></i>
                                                                                            Driving License:</span>
                                                                                        <span class="team-single-info-right">{bookingDetail.driverData.DriverDetail.drivingLicenceNumber}</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div></>}
                                                        <div class="user-profile-card-header mt-5">
                                                            <h4 class="user-profile-card-title">User Details</h4>

                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-4">
                                                                <div class="form-group">
                                                                    <label>Full Name</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].customerData?.name} placeholder="Your Name" />
                                                                    <i class="far fa-user"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="form-group">
                                                                    <label>Phone Number</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].customerData?.phoneNumber} placeholder="Your Phone" />
                                                                    <i class="far fa-phone"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="form-group">
                                                                    <label>Email</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].customerData?.email} placeholder="Your Email" />
                                                                    <i class="far fa-envelope"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Pick Up Location</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].pickupLocation.addressLine} placeholder="Type Location" />
                                                                    <i class="far fa-location-dot"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Drop Off Location</label>
                                                                    <input type="text" class="form-control" disabled={true} value={bookingDetail?.BookingDetails[0].dropLocation.addressLine} placeholder="Type Location" />
                                                                    <i class="far fa-location-dot"></i>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Pick Up Date </label>
                                                                    <input type="text" class="form-control " disabled={true} value={moment(bookingDetail?.tripStartDate).format('DD/MM/YY, hh:mm a')} placeholder="MM/DD/YY" />
                                                                    <i class="far fa-calendar-days"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>Dropping date</label>
                                                                    <input type="text" class="form-control" disabled={true} value={moment(bookingDetail?.tripEndDate).format('DD/MM/YY, hh:mm a')} placeholder="00:00 AM" />
                                                                    <i class="far fa-calendar-days"></i>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12">
                                                                <div class="form-group">
                                                                    <label>Your Message</label>
                                                                    <textarea class="form-control" rows="5" disabled={true} value={bookingDetail.BookingDetails[0].customerNote}
                                                                        placeholder="Write Your Message"></textarea>
                                                                </div>
                                                            </div>
                                                            {(bookingDetail.BookingDetails[0].bookingStatus == 'ended') && <>
                                                                <div class="row align-items-center mt-5">
                                                                    <a className="col-md-6 col-12"><div class="taxi-img">
                                                                        <img src={constant.IMAGE_URL + bookingDetail?.vendorData.VendorDetail.documents} crossorigin="anonymous" width={'350px'} />
                                                                    </div></a>
                                                                    <div class="taxi-content col-md-6 col-12">
                                                                        <div class="taxi-head">
                                                                            <h4>Payment Break Down</h4>

                                                                        </div>
                                                                        <div class="taxi-feature">
                                                                            <ul className="">

                                                                                <li> Total Amount to be paid: <span> <b>Rs. {((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.carData.perKmCharge+1 : bookingDetail.carData.perKmCharge)).toFixed(0)}</b></span></li>
                                                                                <li> Amount paid online: <span> <b className="bg-success text-white">Rs. {isPayment && isPayment / 100}</b></span></li>
                                                                                <li> Amount remaining: <span> <b className="bg-warning text-white">Rs. {(((bookingDetail.distanceBreakdown.distance / 1000) * (bookingDetail.isAcAvailable ? bookingDetail.carData.perKmCharge+1 : bookingDetail.carData.perKmCharge)) - (isPayment / 100)).toFixed(0)}</b></span></li>
                                                                                <li>Add meal cost: <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="number" className="border-0 text-right" onChange={(e) => setMealCost(e.target.value)} value={mealCost} placeholder="Enter Meal Cost" /></span></li>
                                                                                <li>Add toll tax: <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="number" className="border-0 text-right" onChange={(e) => setTollTax(e.target.value)} value={tollTax} placeholder="Enter Toll Tax" /></span></li>
                                                                                <li>Add other charges: <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="number" className="border-0 text-right" onChange={(e) => setOtherCost(e.target.value)} value={otherCost} placeholder="Enter Other Charges" /></span></li>
                                                                                <hr />
                                                                                <li>Total Amount remaining: <span> <h4>Rs. {bookingDetail && calculationHandler()}</h4></span></li>
                                                                                <li>Payment Mode: <span>
                                                                                    <select className="border-0 text-right" disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} value={paymentMode} onChange={(e) => setPaymentMode(e.target.value)}>
                                                                                        <option>Choose payment mode</option>
                                                                                        <option value={'cash'}>Cash</option>
                                                                                        <option value={'online'}>Online</option>
                                                                                    </select></span></li>
                                                                                {paymentMode == 'online' && <li>Payment reference <span> <input disabled={bookingDetail.BookingDetails[0].bookingStatus == 'ended'} type="text" className="border-0 text-right" onChange={(e) => setPaymentRef(e.target.value)} value={paymentRef} placeholder="Payment ref" /></span></li>}


                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                    {bookingDetail.BookingReviews.length > 0 && <><div class="col-lg-6">
                                                                    <label>Share your Rating</label> <br/>
                                                                        <Rating
                                                                            initialValue={bookingDetail.BookingReviews[0].rating}
                                                                        />
                                                                    </div>


                                                                    <div class="col-lg-12">
                                                                        <div class="form-group">
                                                                            <label>Share your review</label>
                                                                            <textarea class="form-control" rows="5" value={bookingDetail.BookingReviews[0].comment} 
                                                                                placeholder="Write Your Message"></textarea>
                                                                        </div>
                                                                    </div></>}
                                                                </div>
                                                            </>}

                                                           
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        {/* <Footer /> */}
    </>
    )
}
export default OrderDetails